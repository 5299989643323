import cn from 'classnames'
import * as React from 'react'
import { PositionsTable, PositionsTableProps } from 'src/design-system'

export type PositionsPageProps = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  'children'
> &
  Omit<PositionsTableProps, keyof React.HTMLAttributes<HTMLDivElement>>

export const PositionsPage: React.VFC<PositionsPageProps> = (props) => {
  const { disciplines, eventHandlers, permissions, className, ...restProps } =
    props

  return (
    <div
      className={cn(
        'flex flex-col items-center justify-center overflow-x-auto max-w-full min-h-full',
        className
      )}
      {...restProps}
    >
      <PositionsTable
        disciplines={disciplines}
        eventHandlers={eventHandlers}
        permissions={permissions}
      />
    </div>
  )
}
